import React, { useState, useEffect } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '../../components/loader';
import { PageTitle } from '../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../containers/ScreenContainers';
import { getProfileDetails, getStaffArenaDetails } from '../../redux/staff-arena/actions';

import Experience from './tabs/Experience';
import Overview from './tabs/overview';
import Payroll from './tabs/Payroll';
import Personal from './tabs/personal';
import Publications from './tabs/Publications';
import TeachingStaffExperience from './tabs/TeachingStaffExperience';
import UploadDetails from './tabs/Uploads';

const StaffProfile = () => {
    const [, /*tab*/ setTab] = useState(0);
    const dispatch = useDispatch();

    const { staffData: data } = useSelector((store) => store.user);
    const { staffBio, staffAcad, staff, staffUploads, staffPublications } = useSelector((store) => store.staffArena);

    useEffect(() => {
        if (!data) return;
        dispatch(getProfileDetails(data._id));
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        dispatch(getStaffArenaDetails());
    }, [dispatch]);

    if (!data) return <Loader />;

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle>Staff Arena</PageTitle>
            </FlexRowSpaceBetween>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink onClick={() => setTab(0)}>Overview</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(1)}>Bio-Data</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(2)}>Experience</CNavLink>
                    </CNavItem>

                    {data?.staffType === 'Teaching' && (
                        <>
                            <CNavItem>
                                <CNavLink onClick={() => setTab(1)}>Publications</CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink onClick={() => setTab(2)}>Uploads</CNavLink>
                            </CNavItem>
                        </>
                    )}
                    <CNavItem>
                        <CNavLink onClick={() => setTab(5)}>Payroll</CNavLink>
                    </CNavItem>
                </CNav>

                <CTabContent>
                    <CTabPane>
                        <Overview details={staff} bio={staffBio} acad={staffAcad} />
                    </CTabPane>
                    <CTabPane>
                        <Personal details={staff} bio={staffBio} />
                    </CTabPane>
                    <CTabPane>
                        {data?.staffType === 'Teaching' ? (
                            <TeachingStaffExperience acad={staffAcad} />
                        ) : (
                            <Experience acad={staffAcad} />
                        )}
                    </CTabPane>

                    {data?.staffType === 'Teaching' && (
                        <>
                            <CTabPane>
                                <Publications pub={staffPublications} />
                            </CTabPane>
                            <CTabPane>
                                <UploadDetails uploads={staffUploads} />
                            </CTabPane>
                        </>
                    )}
                    <CTabPane>
                        <Payroll id={data?._id} />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default StaffProfile;
